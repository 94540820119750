<template>
  <div>
    <div v-if="!loaded">
    </div>
    <div v-else class="container Nunito" style="max-width: 100%">
      <section class="section headerSection pfp" :style="(Number(profile.premium) >= 1 && profile.background !== null ? `background-image: url('${profile.background.includes('base64') ? profile.background : `https://cdn.gracebot.net/grace/uploads/${profile.background}.png`}');background-repeat:no-repeat;background-size:cover;background-position: center;` : '' )" style="padding-bottom: 1rem;">
        <button v-if="(this.$store.getters.user ? this.$store.getters.user.id : null) === profile.id && Number(profile.premium) >= 1" @click="$store.dispatch('uploadUserBackground')" class="delete change-button"></button>
        <div class="container has-text-centered">
          <img v-if="profile.avatar" class="icon-img has-shadow" style="width: 128px;" :src="'https://cdn.discordapp.com/avatars/' + profile.id + '/' + profile.avatar + '.' + (profile.avatar.includes('a_') ? 'gif' : 'webp') + '?size=128'" :onerror="'this.src=\'https://cdn.discordapp.com/embed/avatars/' + (Math.floor(1000 + Math.random() * (profile.discriminator ? profile.discriminator : 9000)) % 5) + '.png?size=128\';'" :alt="profile.username">
          <h1 style="margin-bottom: 0px" class="title noselect">{{ profile.username }}<span v-if="!this.$store.getters.admin.streamerMode" class="subtitle has-text-grey-light">#{{ profile.discriminator }}</span></h1>
          <div>
            <img v-if="profile.public_flags_Array.includes('EARLY_SUPPORTER')" class="badge" src="https://cdn.gracebot.net/badges/early_supporter_badge.png" />
            <img v-if="profile.public_flags_Array.includes('VERIFIED_DEVELOPER') || profile.public_flags_Array.includes('EARLY_VERIFIED_BOT_DEVELOPER')" class="badge" src="https://cdn.gracebot.net/badges/verified_developer_badge.png" />
            <img v-if="profile.public_flags_Array.includes('BUGHUNTER_LEVEL_2') || profile.public_flags_Array.includes('BUGHUNTER_LEVEL_1')" class="badge" src="https://cdn.gracebot.net/badges/bug_hunter_badge.png" />
            <img v-if="profile.public_flags_Array.includes('PARTNERED_SERVER_OWNER')" class="badge" src="https://cdn.gracebot.net/badges/partner_badge.png" />
            <img v-if="profile.public_flags_Array.includes('DISCORD_EMPLOYEE')" class="badge" src="https://cdn.gracebot.net/badges/staff_badge.png" />
            <img v-if="profile.public_flags_Array.includes('HYPESQUAD_EVENTS')" class="badge" src="https://cdn.gracebot.net/badges/HypeSquad_events_badge.png" />
            <img v-if="profile.public_flags_Array.includes('HOUSE_BRAVERY')" class="badge" src="https://cdn.gracebot.net/badges/bravery_badge.png" />
            <img v-if="profile.public_flags_Array.includes('HOUSE_BRILLIANCE')" class="badge" src="https://cdn.gracebot.net/badges/brilliance_badge.png" />
            <img v-if="profile.public_flags_Array.includes('HOUSE_BALANCE')" class="badge" src="https://cdn.gracebot.net/badges/balance_badge.png" />
            <router-link tag="img" :to="{path: '/vote'}" v-if="profile.hasVoted" style="cursor: pointer" class="badge" title="This user has voted on top.gg!" src="https://cdn.gracebot.net/grace/voted.png" />
            <img v-if="this.$MomentJS().diff(this.$MomentJS((this.profile.dateJoined * 1000)), 'weeks', true) <= 1" title="Hello! Im new here!" class="badge" src="https://cdn.gracebot.net/badges/gracebot_new_badge.png" />
            <p style="margin-top: -1px;" v-if="Number(profile.staffType) > 0 || Number(profile.premium) > 0" ><b-tag rounded v-if="Number(profile.staffType) > 0 || Number(profile.premium)  > 0" :type="'is-' + (Number(profile.staffType) >= 10 ? 'black' : (Number(profile.staffType) >= 3 ? 'danger' : 'warning') )">{{ userLevelText.toUpperCase() }}</b-tag> <b-tag type="is-danger" v-if="profile.banned">Banned</b-tag></p>
          </div>
        </div>
      </section>
      <section class="section" style="padding-top: 1.5rem;">
          <div class="columns is-centered">
            <div class="column is-centered is-three-quarters has-shadow" style="border-radius: 10px; background: var(--main-color-bg-2); min-height: 500px">
              <b-tabs animated position="is-centered">
                <b-tab-item icon="info" label="Profile">
                  <h1 class="title noselect has-text-centered">Info on {{ profile.username }}!</h1>
                  <div class="columns has-text-centered">
                    <div class="column">
                      <h1 class="title is-4">Coins 💸</h1>
                      <h1 class="subtitle has-text-grey-light noselect">${{ Number(profile.coins).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') }}</h1>
                    </div>
                    <div class="column is-two-fifths">
                      <h1 class="title is-4">Is Trading 🛒</h1>
                      <h1 class="subtitle has-text-grey-light noselect">{{ Number(profile.allowedTrade) === 1 ? 'Yes' : 'No' }}</h1>
                    </div>
                    <div class="column">
                      <h1 class="title is-4">Grace User Level 👑</h1>
                      <h1 class="subtitle has-text-grey-light noselect">{{ userLevelText }}</h1>
                    </div>
                  </div>
                  <div class="columns has-text-centered">
                    <div class="column">
                      <h1 class="title is-4">Robot 🤖</h1>
                      <h1 class="subtitle has-text-grey-light noselect">{{ profile.bot ? 'Yes' : 'No' }}</h1>
                    </div>
                    <div class="column is-two-fifths">
                      <h1 class="title is-4">Banned 🚫</h1>
                      <h1 class="subtitle has-text-grey-light noselect">{{ Number(profile.banned) === 1 ? 'Yes' : 'No' }}</h1>
                    </div>
                    <div class="column">
                      <h1 class="title is-4">All Public Flags</h1>
                      <h1 class="subtitle has-text-grey-light noselect">{{ profile.public_flags_Array.map(g => g.toLowerCase().split("_").map(f => f.charAt(0).toUpperCase() + f.slice(1)).join(" ")).join(", ") }}</h1>
                    </div>
                  </div>
                  <div class="columns has-text-centered">

                    <div class="column">
                      <b-tooltip label="This means when the user was added to the grace system!" type="is-info" multilined>
                        <h1 class="title is-4 pulseAnimation2">Date Started</h1>
                      </b-tooltip>
                      <h1 class="subtitle has-text-grey-light noselect" style="margin-bottom: 0px">{{ $MomentJS((profile.dateJoined * 1000)).format("MMMM Do YYYY, h:mm:ss A") }}</h1>
                      <h1 class="subtitle is-6 has-text-grey-light noselect">({{$MomentJS((profile.dateJoined * 1000)).fromNow() }})</h1>
                    </div>
                    <div class="column is-two-fifths" style="visibility: hidden;">
                      <h1 class="title is-4">...</h1>
                    </div>
                    <div class="column" style="visibility: hidden;">
                      <h1 class="title is-4">...</h1>
                    </div>
                  </div>
                    <h1 class="title noselect has-text-centered">Achievement's by {{ profile.username }}!</h1>
                    <p class="subtitle is-5 has-text-success has-text-centered" style="margin-bottom: 5px;">{{profile.achievements_flags_Array.length}}/{{profile.achievements_list.length}} Completed</p>
                    <div style="display: flex;justify-content: center;display: flex; flex-wrap: wrap; max-width: 100%;">
                        <!-- Operational: 0,3 Loading: 4,8,6,7,1 Partial Outage: 2 Major Outage: 5 -->
                        <div v-for="achievement in Array.from(profile.achievements_list)" :key="achievement.id">
                          <div @mouseover="showAVCinfo(achievement)" @mouseout="closeAVCinfo" class="AVC has-shadow Nunito" :class="null">
                              <!-- profile.achievements_flags_Array.includes(achievement.id) -->
                              <img :src="achievement.image" width="64px" :style="!profile.achievements_flags_Array.includes(achievement.id) ? '-webkit-filter: grayscale(1); filter: grayscale(1);' : ''" />
                          </div>
                        </div>
                    </div>
                    <p class="subtitle is-6 has-text-grey-light has-text-centered" style="margin-bottom: 2px;margin-top: 5px;">Icons provided kindly from <a href="https://www.flaticon.com/?ref=gracebot.net" title="Flaticon">flaticon.com</a></p>
                </b-tab-item>

                <b-tab-item id="level" icon="id-card" icon-pack="fas" :disabled="this.defaultwelcomeImages.length <= 0" label="Level Card" v-if="(this.$store.getters.user ? this.$store.getters.user.id : null) === profile.id">
                  <h1 class="title noselect has-text-centered">Manage your Level Card!</h1>
                  <div class="columns has-text-centered">
                    <div class="column">
                      <img :src="demoCard" width="467px" height="141px" />
                    </div>
                  </div>
                  <div class="columns has-text-centered">
                    <div class="column">
                      <h1 class="title is-4">Background Image</h1>
                      <h1 class="subtitle has-text-grey-light noselect">{{ Number(this.$store.getters.user.premium) >= 1 ? 'Enjoy limited edition images on events!' : 'Upgrade to unlock custom backgrounds!' }}</h1>
                      <!-- <h1 class="subtitle has-text-grey-light noselect"></h1> -->
                      <v-select appendToBody :calculatePosition="vselectfixer" @input="backgroundChange" :disabled="false" :searchable="false" required :clearable="false" :reduce="item => item" :options="(this.$store.getters.user.premium > 0 && this.$store.getters.user.background ? [{title: 'Custom Background (Uploaded)', cardImage: 'https://cdn.gracebot.net/grace/uploads/' + this.$store.getters.user.background + '.png', fileName: 'custom'}, ...((this.$store.getters.user ? this.$store.getters.user.premium : 0) > 0 ? this.defaultwelcomeImages.filter(g => g.show === true ) : this.defaultwelcomeImages.filter(g => g.show === true).filter(g => g.premiumOnly === false))] : ((this.$store.getters.user ? this.$store.getters.user.premium : 0) > 0 ? this.defaultwelcomeImages.filter(g => g.show === true ) : this.defaultwelcomeImages.filter(g => g.show === true).filter(g => g.premiumOnly === false)))" :value="(this.$store.getters.user.cardBackground === 'custom' && this.$store.getters.user.background ? {title: 'Custom Background (Upload)', cardImage: 'https://cdn.gracebot.net/grace/uploads/' + this.$store.getters.user.background + '.png', fileName: 'custom'} : this.defaultwelcomeImages.find(g => g.fileName === this.$store.getters.user.cardBackground))" label="title">
                        <template slot="option" slot-scope="option">
                          <div class="columns level" style="padding-right: 0px !important;">
                            <div class="column is-half" style="text-overflow: ellipsis;white-space: pre-line;overflow: hidden;color: white;">
                              {{ option.title }}
                            </div>
                            <div class="column is-half">
                              <img :src="option.cardImage" width="200" />
                            </div>
                          </div>
                      </template>
                    </v-select>
                    </div>
                    <div class="column is-two-fifths">
                      <h1 class="title is-4">Color</h1>
                      <h1 class="subtitle has-text-grey-light noselect">Feel free to enter your own color!</h1>
                      <JsColor @finish="cardColor" :disabled="BackgroundImageDisabled" id="color" v-model="$store.getters.user.color"></JsColor>
                    </div>
                    <div class="column">
                      <h1 class="title is-4">Upload Background</h1>
                      <h1 class="subtitle has-text-grey-light noselect">Or click the profile navbar!</h1>
                      <b-button :disabled="Number(this.$store.getters.user.premium) <= 0" type="is-info" @click="$store.dispatch('uploadUserBackground')">Upload Custom Background</b-button>
                    </div>
                  </div>
                </b-tab-item>

                <b-tab-item id="patreon" icon="patreon" icon-pack="fab" label="Manage Patreon" v-if="(this.$store.getters.user ? this.$store.getters.user.id : null) === profile.id && (this.$store.getters.user ? this.$store.getters.user.patreonID : null) !== null">
                  <h1 class="title noselect has-text-centered">Manage Patreon.</h1>
                  <b-notification type="is-danger" aria-close-label="Close notification" role="alert">
                    Please update your subscription plan to BuyMeACoffee!<br>Cancel your pledge, unlink account, head over to our new premium page and buy via BuyMeACoffee, relink account!<br><b>If you need any help feel free to contact our support on discord!</b>
                  </b-notification>
                  <div class="columns has-text-centered" v-if="this.$store.getters.user.patreonID || premiumGuilds.length > 0 || Number(this.profile.premium) !== 0">
                    <div class="column">
                      <h1 class="title is-4">Premium Type</h1>
                      <h1 class="subtitle has-text-grey-light noselect">{{ premiumType }}</h1>
                    </div>
                    <div class="column">
                      <b-tooltip type="is-warning" :multilined="true" label="You must be an Manager+ (Manage_Server) in the server in order to give it premium! Guilds that are premium by someone else wont show!">
                        <h1 class="title is-4 pulseAnimation2">Premium Guilds {{premiumLimit.currentAmount}}/{{premiumLimit.limit}}</h1>
                      </b-tooltip>
                      <v-select style="padding-left: 10%; padding-right: 10%;" appendToBody :selectOnTab="true" placeholder="Search or Select a server to add to your premium!" :calculatePosition="vselectfixer" @input="guildSelectUpdate" :disabled="guildSelectDisabled" autocomplete :searchable="true" :multiple="true" required :clearable="true" :reduce="item => item.id" :options="allowedGuildsOptions" :value="premiumGuilds" :getOptionLabel="option => (option.name ? option.name : (guilds.find(g => g.id === option) ? guilds.find(g => g.id === option).name : (option ? `Server ID ${option}` : 'Missing Server')))" label="Guild Select Role">
                        <template slot="option" slot-scope="option" style="width: 100%">
                          <b-tooltip :label="`ID: ${option.id}`" style="width: 100%" position="is-bottom" multilined>
                            <div class="" style="padding-right: 0px !important;color:white!important; padding-top: 10px; padding-bottom: 10px; width:100%">
                              <span class="Nunito" :style="`color: white;`">{{ option.name }}</span> <b-tag class="Nunito" v-if="option.owner" rounded type="is-warning">Owner</b-tag>
                            </div>
                          </b-tooltip>
                        </template>
                      </v-select>
                    </div>
                  </div>
                  <div class="columns has-text-centered">
                    <div class="column">
                      <h1 class="title is-4">{{!this.$store.getters.user.patreonID ? 'Connect Your Account' : 'Resync Account or Unlink Account'}}</h1>
                      <div class="buttons" style="margin-bottom: unset !important; display: block;">
                        <b-button icon-left="patreon" icon-pack="fab" type="is-warning" tag="router-link" :to="{ path: '/patreon/login' }">{{!this.$store.getters.user.patreonID ? 'Connect Account' : 'Resync Account'}}</b-button>
                        <b-button icon-left="patreon" v-if="this.$store.getters.user.patreonID" icon-pack="fab" type="is-danger" tag="router-link" :to="{ path: '/patreon/delete' }">Unlink Account</b-button>
                      </div>
                    </div>
                    <div class="column">
                      <h1 class="title is-4">Patreon Page</h1>
                      <b-button icon-left="patreon" icon-pack="fab" type="is-info" tag="a" href="https://www.patreon.com/bePatron?u=32847695">Patreon Page</b-button>
                    </div>
                  </div>
                </b-tab-item>

                <b-tab-item id="premium" icon="dollar-sign" label="Manage Premium" v-else-if="(this.$store.getters.user ? this.$store.getters.user.id : null) === profile.id">
                  <h1 class="title noselect has-text-centered">Manage Premium.</h1>
                  <b-notification type="is-warning" aria-close-label="Close notification" role="alert" v-if="!this.$store.getters.user.bmcSubID">
                    We recommened that you connect your BMC Subscriptions here after you purchases! (resyncs hourly)<br><b>Please use the same email account connect as your discord account when purchasing</b>
                  </b-notification>
                  <div class="columns has-text-centered" v-if="!bmcSyncLoading && (this.$store.getters.user.bmcSubID || premiumGuilds.length > 0 || Number(this.profile.premium) !== 0)">
                    <div class="column">
                      <h1 class="title is-4">Premium Type</h1>
                      <h1 class="subtitle has-text-grey-light noselect">{{ premiumType }}</h1>
                    </div>
                    <div class="column">
                      <b-tooltip type="is-warning" :multilined="true" label="You must be an Manager+ (Manage_Server) in the server in order to give it premium! Guilds that are premium by someone else wont show!">
                        <h1 class="title is-4 pulseAnimation2">Premium Guilds {{premiumLimit.currentAmount}}/{{premiumLimit.limit}}</h1>
                      </b-tooltip>
                      <v-select style="padding-left: 10%; padding-right: 10%;" appendToBody :selectOnTab="true" placeholder="Search or Select a server to add to your premium!" :calculatePosition="vselectfixer" @input="guildSelectUpdate" :disabled="guildSelectDisabled" autocomplete :searchable="true" :multiple="true" required :clearable="true" :reduce="item => item.id" :options="allowedGuildsOptions" :value="premiumGuilds" :getOptionLabel="option => (option.name ? option.name : (guilds.find(g => g.id === option) ? guilds.find(g => g.id === option).name : (option ? `Server ID ${option}` : 'Missing Server')))" label="Guild Select Role">
                        <template slot="option" slot-scope="option" style="width: 100%">
                          <b-tooltip :label="`ID: ${option.id}`" style="width: 100%" position="is-bottom" multilined>
                            <div class="" style="padding-right: 0px !important;color:white!important; padding-top: 10px; padding-bottom: 10px; width:100%">
                              <span class="Nunito" :style="`color: white;`">{{ option.name }}</span> <b-tag class="Nunito" v-if="option.owner" rounded type="is-warning">Owner</b-tag>
                            </div>
                          </b-tooltip>
                        </template>
                      </v-select>
                    </div>
                  </div>
                  <div class="columns has-text-centered">
                    <div class="column">
                      <h1 class="title is-4">{{!this.$store.getters.user.bmcSubID ? 'Link Membership' : 'Ublink Membership'}}</h1>
                      <div class="buttons" style="margin-bottom: unset !important; display: block;">
                        <b-button icon-left="dollar-sign" v-if="!this.$store.getters.user.bmcSubID" :loading="bmcSyncLoading" type="is-warning" @click="bmcSync(false)">Link Membership</b-button>
                        <b-button icon-left="dollar-sign" v-else-if="this.$store.getters.user.bmcSubID" :loading="bmcSyncLoading" type="is-danger" @click="bmcSync(true)">Unlink Membership</b-button>
                      </div>
                    </div>
                    <div class="column">
                      <h1 class="title is-4">Buy Me A Coffee Page</h1>
                      <b-button :disabled="bmcSyncLoading" icon-left="dollar-sign" type="is-info" tag="a" href="https://www.buymeacoffee.com/GraceBot">Buy Me A Coffee Page</b-button>
                    </div>
                  </div>
                </b-tab-item>
              </b-tabs>
            </div>
          </div>
            <div class="container" style="width:50%">
              <div class="columns is-mobile is-centered is-multiline">
                <b-tooltip :v-if="(this.$store.getters.user ? (this.$store.getters.user.premium <= 0) : true)" type="is-info">
                  <template v-slot:content><b>Why do we show ads?</b>. <br>The simple answer is to make some money to keep us going.<br>We made sure to place them in a way so your expirence isnt damaged.<br>If you dont want to see ads support us by getting premium!</template>
                  <!-- <Adsense v-if="showAds && !this.$window767.matches" style="text-align: center;" ins-style="display:block;margin-top: 25px;height:90px;width:728px;text-align: center;" :data-ad-client="this.$store.getters.google.adClient" :data-ad-slot="this.$store.getters.google.profile"></Adsense>
                  <Adsense v-else-if="showAds && this.$window767.matches" style="text-align: center;" ins-style="display:block;margin-top: 25px;height:250px;width:250px;text-align: center;" :data-ad-client="this.$store.getters.google.adClient" :data-ad-slot="this.$store.getters.google.profile"></Adsense> -->
                </b-tooltip>
              </div>
            </div>
      </section>
    </div>
  </div>
</template>

<script>
  import 'vue-select/dist/vue-select.css'
  import vSelect from 'vue-select'
  import { createPopper } from '@popperjs/core'
  import JsColor from '@/components/JsColor.vue'

  export default {
    name: 'user-profile',
    components: {
      vSelect,
      JsColor
    },
    data () {
      return {
        loaded: false,
        profile: null,
        // items: [],
        activeCard: null,
        retryamount: 0,
        SelectPlacement: 'bottom',
        abbrNumber: n => {
          if (n < 1e3) return n
          if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
          if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
          if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
          if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
        },
        guildSelectDisabled: false,
        guilds: [],
        premiumGuilds: [],
        allowedGuildsOptions: [],
        showAds: true,
        BackgroundImageDisabled: false,
        bmcSyncLoading: false,
        defaultwelcomeImages: []
      }
    },
    watch: {
      '$store.getters.user.premiumGuilds' () {
        this.premiumGuilds = JSON.parse((!this.$store.getters.user.premiumGuilds ? '[]' : this.$store.getters.user.premiumGuilds))
        this.runAllowedGuilds()
      },
      'guilds' () {
        this.runAllowedGuilds()
      }
    },
    async created () {
      // this.$store.dispatch('getApi', { path: 'system/items', params: {} }).then(async (result) => {
      //   this.items = result.data
      // })
      this.getProfile()
    },
    methods: {
      closeAVCinfo () {
        if (this.activeCard === null) return
        this.activeCard.close()
        this.activeCard = null
      },
      showAVCinfo (AVC) {
        if (!AVC) return
        this.activeCard = this.$buefy.snackbar.open({
          message: `<p class="title is-3 Nunito">Achievement #${AVC.id}: ${AVC.name.charAt(0).toUpperCase() + AVC.name.slice(1)}</p>
          <p class="subtitle is-5 has-text-white Nunito" style="margin-bottom: 5px;">Description: ${AVC.description}</p>
          <p class="subtitle is-5 has-text-white Nunito" style="">Rarity: ${AVC.rarity.charAt(0).toUpperCase() + AVC.rarity.slice(1)}</p>`,
          type: 'is-info',
          queue: false,
          indefinite: true,
          actionText: null
        })
      },
      vselectfixer (dropdownList, component, { width }) {
        dropdownList.style.width = width
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.SelectPlacement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -1]
              }
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn ({ state }) {
                component.$el.classList.toggle('drop-up', state.SelectPlacement === 'top')
              }
            }
          ]
        })
        return () => popper.destroy()
      },
      async bmcSync (del = false) {
        this.bmcSyncLoading = true
        this.$store.dispatch(del ? 'deleteApi' : 'postApi', { path: (del ? 'plugin/bmc/delete' : 'plugin/bmc/sync'), body: {}, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: del ? 'We have unlinked your memebership!' : 'All set! (May take 5s to update!)',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          await this.$store.dispatch('sleep', 2000)
          this.$store.dispatch('getUser')
          this.bmcSyncLoading = false
          this.getProfile()
        }).catch(async (error) => {
          console.log(error, error.response, 'Error')
          if ((error.response.data ? error.response.data.systemId : null)) {
            const systemID = this.$store.getters.admin.systemIds[Number(error.response.data.systemId)]
            this.$buefy.snackbar.open({
              message: systemID.msg + '<br>' + (systemID.helpURL ? `<a href="${systemID.helpURL}">Click Here for more!</a>` : ''),
              type: 'is-warning',
              position: 'is-bottom-right',
              duration: 35000
            })
          } else {
            this.$buefy.snackbar.open({
              message: 'Oh no! Some error happened!',
              type: 'is-danger',
              position: 'is-bottom-right'
            })
          }
          await this.$store.dispatch('sleep', 2000)
          this.$store.dispatch('getUser')
          this.bmcSyncLoading = false
          this.getProfile()
        })
      },
      async getProfile () {
        if (!this.$store.getters.user) await this.$store.dispatch('sleep', 1000)
        this.$store.dispatch('getApi', { path: `plugin/profile/${(this.$route.params.id === ('@me' || 'me') ? (this.$store.getters.user ? this.$store.getters.user.id : this.$route.params.id) : this.$route.params.id)}`, params: {} }).then(async (result) => {
          this.profile = result.data.user
          this.showAds = Number(this.profile.premium) <= 0
          // if (this.showAds) this.showAds = Number(this.profile.staffType) <= 0
          if (this.showAds) this.showAds = (this.$store.getters.user ? (this.$store.getters.user.premium <= 0) : true)

          if (this.profile.id === (this.$store.getters.user ? this.$store.getters.user.id : null)) {
            this.$store.dispatch('getApi', { path: 'system/welcomeImages', params: (this.$store.getters.build === 'development' ? { url: `${window.location.protocol}//${window.location.host}` } : {}) }).then(async (result) => {
              this.defaultwelcomeImages = result.data
            }).catch(async (error) => {
              console.log(error, error.response, 'Error')
              this.defaultwelcomeImages = []
            })
            this.premiumGuilds = JSON.parse((!this.$store.getters.user.premiumGuilds ? '[]' : this.$store.getters.user.premiumGuilds))
            this.$store.dispatch('getGuilds', {
              // filter: 'isOwner',
              sort: 'alphabetically'
            }).then(async (data) => {
              this.guilds = data
              this.loaded = true
            })
          } else {
            this.loaded = true
          }
        }).catch((error) => {
          console.log(error, 'error')
          this.retryamount += 1
          if (Number(error.response.status) === 404) {
            if (this.retryamount <= 3) return this.getProfile()
            return this.$router.push('/404?reason=User not found')
          }
          if (Number(error.response.status) === 500) {
            if (this.retryamount <= 3) return this.getProfile()
            return this.$router.push('/500?reason=' + error)
          }
          if (this.retryamount <= 3) return this.getProfile()
          return this.$router.push('/500?reason=unknown')
        })
      },
      backgroundChange (value) {
        // const beforeUpdate = this.$store.getters.user.cardBackground
        this.BackgroundImageDisabled = true
        // this.guild.guildDB.data.welcomeBackground = value
        this.$store.dispatch('patchApi', { path: 'user/profile', body: { action: 'background', value: value.fileName }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: 'Background Image was updated!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          this.BackgroundImageDisabled = false
          await this.$store.dispatch('sleep', 2000)
          this.$store.dispatch('getUser')
          this.getProfile()
        }).catch(async (error) => {
          console.log(error, 'er')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          // this.guild.guildDB.data.welcomeBackground = beforeUpdate
          this.BackgroundImageDisabled = false
          await this.$store.dispatch('sleep', 2000)
          this.$store.dispatch('getUser')
          this.getProfile()
        })
      },
      cardColor (value) {
        this.$store.dispatch('patchApi', { path: 'user/profile', body: { action: 'color', value: value }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: 'Color was updated!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          await this.$store.dispatch('sleep', 2000)
          this.$store.dispatch('getUser')
          this.getProfile()
        }).catch(async (error) => {
          console.log(error, 'Error')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          await this.$store.dispatch('sleep', 2000)
          this.$store.dispatch('getUser')
          this.getProfile()
        })
      },
      guildSelectUpdate (value) {
        value = value.filter(r => r)
        const beforeUpdate = this.premiumGuilds
        this.guildSelectDisabled = true
        this.premiumGuilds = value
        this.$store.dispatch('patchApi', { path: 'plugin/patreon/premiumupdate', body: { ids: value }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: 'Premium Guilds was updated!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          this.guildSelectDisabled = false
          await this.$store.dispatch('sleep', 2000)
          this.getProfile()
          this.$store.dispatch('getUser')
        }).catch(async (error) => {
          console.log(error, 'er')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          this.premiumGuilds = beforeUpdate
          this.guildSelectDisabled = false
          await this.$store.dispatch('sleep', 2000)
          this.getProfile()
          this.$store.dispatch('getUser')
        })
      },
      runAllowedGuilds () {
        if (!this.guilds || !this.premiumGuilds) return
        this.allowedGuildsOptions = []
        const guilds = this.guilds.filter(g => g.guildDB.data).filter(g => g.isManager)
        for (let i = 0; i < guilds.length; i++) {
          const guild = guilds[i]
          if (this.premiumGuilds.includes(guild.id)) { this.allowedGuildsOptions.push(guild); continue }
          if ((guild.guildDB ? guild.guildDB.data : false) ? guild.guildDB.data.banned : false) { continue }
          if ((guild.guildDB ? guild.guildDB.data : false) ? guild.guildDB.data.premium : true) { continue }
          this.allowedGuildsOptions.push(guild)
        }
      }
    },
    mounted () {
    },
    computed: {
      userLevelText () {
        // const numb = Number(this.profile.userLevel)
        // if (Number(this.profile.staffType) === 0) return 'Default'
        if (Number(this.profile.staffType) === 1) return 'Partner'
        if (Number(this.profile.staffType) === 2) return 'Helpful/Trusted'
        if (Number(this.profile.staffType) === 3) return 'Helper'
        if (Number(this.profile.staffType) === 4) return 'Mod'
        if (Number(this.profile.staffType) === 5) return 'Admin'
        if (Number(this.profile.staffType) === 6) return 'Sr Admin'
        if (Number(this.profile.staffType) === 7) return 'Dev'
        if (Number(this.profile.staffType) === 8) return 'Management'
        if (Number(this.profile.staffType) === 9) return 'Owner'
        if (Number(this.profile.staffType) === 10) return 'System'

        if (Number(this.profile.premium) === 1) return 'Premium (User)'
        if (Number(this.profile.premium) === 2) return 'Premium +'
        if (Number(this.profile.premium) === 3) return 'Premium ++'
        if (Number(this.profile.premium) === 4) return 'Premium +++'
        return 'Default'
      },
      premiumType () {
        if (Number(this.profile.premium) === 1) return 'User Premium'
        if (Number(this.profile.premium) === 2) return 'Super Premium (2 Servers)(+)'
        if (Number(this.profile.premium) === 3) return 'Super Plus Premium (5 Servers)(++)'
        if (Number(this.profile.premium) === 4) return 'Very Super Plus Premium (10 Servers)(+++)'
        return 'None'
      },
      premiumLimit () {
        return {
          currentAmount: (this.$store.getters.user ? JSON.parse((!this.$store.getters.user.premiumGuilds ? '[]' : this.$store.getters.user.premiumGuilds)) : []).length,
          limit: (Number(this.profile.premium) === 4 ? 10 : (Number(this.profile.premium) === 3 ? 5 : (Number(this.profile.premium) === 2 ? 2 : 0)))
        }
      },
      demoCard () {
        const url = new URL(this.$store.getters._genApiUrl('plugin/level/icon') + '?type=beta')
        if (this.$store.getters.user.avatar) url.searchParams.set('pfp', 'https://cdn.discordapp.com/avatars/' + this.$store.getters.user.id + '/' + this.$store.getters.user.avatar + '.png?size=128')
        url.searchParams.set('background', (this.$store.getters.user.cardBackground === 'custom' && this.$store.getters.user.background ? this.$store.getters.user.background : this.$store.getters.user.cardBackground))
        url.searchParams.set('premium', this.$store.getters.user.premium > 0)
        url.searchParams.set('username', this.$store.getters.user.username)
        url.searchParams.set('discriminator', this.$store.getters.user.discriminator)
        url.searchParams.set('xp', Math.floor(Math.random() * (450 - 5) + 5))
        url.searchParams.set('color', this.$store.getters.user.color.replace(/^#/, ''))
        url.searchParams.set('status', 'online')
        return url.toString()
      }
    }
  }
</script>

<style>
  .tabs a {
    color: white;
  }
  .tabs ul {
    border-bottom-color: darkgrey;
  }
  .tabs a:hover {
    border-bottom-color: #7957d5;
    color: #7957d5;
  }
</style>

<style scoped>
  .change-button {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 10px;
    margin-top: 5px;
    background-color: rgba(10, 10, 10, 0.4);
  }

  @media only screen and (max-width: 768px) {
    .pfp:hover .change-button {
      visibility: visible !important;
    }
  }

  /* .change-button {
    visibility: hidden;
  } */

  .pfp:hover .change-button {
    visibility: visible !important;
  }

  .delete:hover::before, .delete:hover::after {
    background-color: #f04747 !important;
  }
  .AVC {
    width: 75px;
    height: 75px;
    background-color: var(--main-color-bg-2);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px;
    font-weight: 500;
    border-radius: 6px;
    transition: .3s;
    cursor: pointer;
    font-size: 20px;
  }
  .AVC:hover {
    filter: brightness(.6);
    -webkit-filter: brightness(.6);
    transform: scale(1.05);
    transition: all .3s ease-in-out;
  }

  section {
    height: 100% !important;
  }
  .has-shadow {
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3) !important;
  }
  .icon-img {
    /* float: left; */
    border-radius: 100px;
    border-color: var(--main-color-bg-3);
    border-width: 4px;
    border-radius: 100px;
    border-style: solid;
    background-color: var(--main-color-bg) !important;
  }
  .badge {
    width: 2rem;
  }
  .badge:not(:first-child) {
    margin-left: 10px;
  }
  .newUser {
    height: 32px !important;
  }
</style>

<style>
  .vs--disabled .vs__dropdown-toggle, .vs--disabled .vs__clear, .vs--disabled .vs__search, .vs--disabled .vs__selected, .vs--disabled .vs__open-indicator {
    background: var(--main-color-bg-2);
    cursor: not-allowed;
    color: #7a7a7a !important;
  }
  .vs__dropdown-toggle {
    background: var(--main-color-bg-2);
    color: white !important;
    border-color: var(--main-color-bg-3) !important;
    min-height: 2.25em !important;
    height: 100% !important;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3) !important;
  }
  .vs__dropdown-menu {
    background: var(--main-color-bg-1);
    color: white;
    border-color: var(--main-color-bg-3) !important;
    border-top-style: unset !important;
    border-radius: 4px 4px 4px 4px !important;
  }
  .vs__open-indicator {
    fill: #6045aa !important;
  }
  .vs__open-indicator:hover {
    fill: #7957d5 !important;
  }
  .vs__clear {
    fill: darkred !important;
  }
  .vs__clear:hover {
    fill: red !important;
  }
  .vs--single .vs__selected {
    color: white;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }
  .vs__dropdown-option--selected {
    background-color: var(--main-color-bg-2);
  }
  .vs__dropdown-option--highlight {
    background: var(--main-color-bg-3) !important;
  }
  .vs__actions {
    cursor: pointer;
  }
  .vs__search, .vs__search:focus {
    color: white;
  }
  .vs__selected {
    background-color: var(--main-color-bg-1);
    color: white;
  }
  .vs__deselect {
    fill: darkred;
  }
  .vs__deselect:hover {
    fill: red;
  }
</style>
